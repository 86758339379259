import React, {useState } from 'react';
import { Link } from 'react-router-dom';
import {useDispatch } from 'react-redux';
import { saveShippingAddress } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';

function ShippingScreen(props) {
  // const userSignin = useSelector((state) => state.userSignin);
  // const { userInfo } = userSignin;
  // const cart = useSelector((state) => state.cart);
  // const { shipping} = cart;

 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');

  const dispatch = useDispatch();

  const collectionHandler = () => {
    props.history.push("/collectionOrder");
    }

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ name, email, address, city, postalCode, country }));
    props.history.push('placeorder');
  }
  

  return <div>
      <CheckoutSteps step1 step2 ></CheckoutSteps>
      <div className="back-to-result">
        <Link to="/">Back to Shop</Link>
      </div>

      <div id = "div1" className="form">
        <form onSubmit={submitHandler} >
          <ul className="form-container">
            <li>
              <h2>Shipping</h2>
            </li>

            <li><label htmlFor="name">
                  Name
                </label>
                <input type="text" name="name" id="name" placeholder="Enter full name" onChange={(e) => setName(e.target.value)}>
                </input></li>
              
            
            
 
            <li>
              <label htmlFor="email">Email: </label>
              <input type="text" name="email" id="email" placeholder="Enter your email address" onChange={(e) => setEmail(e.target.value)}>
              </input>
            </li>
            
            
           
            <li>
              <label htmlFor="address">
                Address
              </label>
              <input type="text" name="address" id="address" placeholder="Enter address" onChange={(e) => setAddress(e.target.value)}>
              </input>
            </li>
            <li>
              <label htmlFor="city">
                City
              </label>
              <input type="text" name="city" id="city" placeholder="Enter city" onChange={(e) => setCity(e.target.value)}>
              </input>
            </li>
            <li>
              <label htmlFor="postalCode">
                Post Code
              </label>
              <input type="text" name="postalCode" id="postalCode" placeholder="Enter postal code" onChange={(e) => setPostalCode(e.target.value)}>
              </input>
            </li>
            <li>
              <label htmlFor="country">
                Country
              </label>
              <input type="text" name="country" id="country" placeholder="Enter country" onChange={(e) => setCountry(e.target.value)}>
              </input>
            </li>
            
            <li>
              <button type="submit" className="button primary">Continue</button>
            </li>
            <div className='collectiontext'>
        If you want to pop in and collect you can save on delivery! <br></br>
            We are at 11 Market Place, Market Lavington, SN10 4AH
        </div>
        <button onClick={collectionHandler} className="button primary" >
              Proceed to Collection Checkout
        </button>

          </ul>
          
        </form>

      </div>
      </div>
}
export default ShippingScreen;