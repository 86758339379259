import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import {Route, BrowserRouter, } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import SigninScreen from './screens/SigninScreen';
import AboutScreen from './screens/AboutScreen';
import { useSelector, useDispatch } from 'react-redux';
import RegisterScreen from './screens/RegisterScreen';
import ProductsScreen from './screens/ProductsScreen';
import ShippingScreen from './screens/ShippingScreen';
import EventScreen from './screens/EventScreen';
import SigninorderScreen from './screens/SigninOrderScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceorderScreen';
import OrderScreen from './screens/OrderScreen';
import HoneyEventScreen from './screens/HoneyEventScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import { signout } from './actions/userActions';
import OrderListScreen from './screens/OrderlistScreen';
import KeywordScreen from './screens/KeywordScreen';
import CollectionScreen from './screens/CollectionsScreen';
import PlaceCollectionScreen from './screens/PlacecollectionScreen';
import DiscountedScreen from './screens/DiscountedScreen';
import DiscountedDeliveryScreen from './screens/DiscountedDeliveryScreen';


function App() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const openMenu = () => {
    document.querySelector('.sidebar').classList.add('open');
  };
  const closeMenu = () => {
    document.querySelector('.sidebar').classList.remove('open');
  };
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
  };
  return (
    <BrowserRouter>
      <div className="grid-container">
        <header className="header">
          <div className="brand">
            <button onClick={openMenu} >
              &#9776;
            </button> 
          </div>
            <div>
              <img className="title-image" src="/images/titleimage.png" ></img>
            </div>
            <div className="header-links">
              <Link to="/" > Shop </Link> 
              <Link to= "/cart">Basket</Link>
              
{/* 
            {userInfo ? (
              <div className="dropdown">
                <a href="#">{userInfo.name} </a>
                
                
              </div>
            ) : (
              <Link to="/signin">Sign In</Link>
            )}
            {userInfo && userInfo.isAdmin && (
              <div className="dropdown">
                <a href="#">Admin</a>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/orders">Orders</Link>
                    <Link to="/products">Products</Link>
                    <Link to="/honeyevents">Events</Link>
                  </li>
                </ul>
              </div>
            )} */}

            </div>
        </header>
        
          <aside className='sidebar'>
            
            <button className="sidebar-close-button" onClick={closeMenu}>x</button>
            <ul>
            <Link to="/AboutUs">
                  <img className="sidebar-image-icon-top" src="images/about.png" alt="sidebar" />
                </Link>
                <li className='sidebar-text-top'> 
                  <Link to="/AboutUs">Find out more about us</Link>
                </li>
              
              
               <Link to="/">
                <img className="sidebar-image-icon-middle" src="images/store.png" alt="sidebar" />
              </Link>
              <li className='sidebar-text-middle'> 
                <Link to="/">See what we have in our shop</Link>
              </li>
              
              <Link to="/events">
                <img className="sidebar-image-icon-bottom" src="images/event.png" alt="sidebar" />
              </Link>
              <li className='sidebar-text-bottom'> 
                <Link to="/events">See what events we are appearing at</Link>
              </li>
            </ul>
            <img className="sidebar-image" src="images/sidebar_image.png" alt="sidebar" />
          </aside>
        
        <main className="main" onClick={closeMenu} >

        </main>
        <main className="main">
            <div className="content">
            <Route path="/signin" component={SigninScreen} />
            <Route path="/signinorders" component={SigninorderScreen} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/AboutUs" component={AboutScreen} />
            <Route path="/payment/:id" component={PaymentScreen} />
            <Route path="/placeorder" component={PlaceOrderScreen} />
            <Route path="/product/:id" component={ProductScreen} />
            <Route path="/cart/:id?" component={CartScreen} />
            <Route path="/products" component={ProductsScreen} />
            <Route path="/honeyevents" component={HoneyEventScreen} />
            <Route path="/shipping" component={ShippingScreen} />
            <Route path="/events" component={EventScreen} />
            <Route path="/order/:id" component={OrderScreen} />
            <Route path="/orderhistory" component={OrderHistoryScreen}></Route>
            <Route path="/orders" component={OrderListScreen}></Route>
            <Route path="/" exact={true} component={HomeScreen} />
            <Route path="/keywords" exact={true} component={KeywordScreen} />
            <Route path="/collectionOrder" exact={true} component={CollectionScreen} />
            <Route path="/placecollectionorder" exact={true} component={PlaceCollectionScreen} />
            <Route path="/discounted" exact={true} component={DiscountedScreen} />
            <Route path="/discounteddelivery" exact={true} component={DiscountedDeliveryScreen} />
            </div>
            
        </main>
        
        <footer className="footer">
          
            Contact us at NectaroftheDodds@gmail.com
          
            
        </footer>
    </div>
    </BrowserRouter>
  );
}

export default App;
