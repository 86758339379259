import Axios from "axios";
import { CART_ADD_ITEM ,CART_REMOVE_ITEM, CART_SAVE_SHIPPING, CART_SAVE_PAYMENT, CART_SAVE_DELIVERY,DISCOUNT_CODE} from "../constants/cartConstants";
// CART_SAVE_MESSAGE
import Cookie from "js-cookie";


const addToCart = (productId, qty,msg) => async (dispatch, getState) => {
  try {
    const { data } = await Axios.get("/api/products/" + productId);
    dispatch({
      type: CART_ADD_ITEM, payload: {
        product: data._id,
        name: data.name,
        image: data.image,
        price: data.price,
        brand: data.brand,
        countInStock: data.countInStock,
        delivery_max: data.delivery_max,
        qty,
        msg
      }
    });
    const { cart: { cartItems } } = getState();
    Cookie.set("cartItems", JSON.stringify(cartItems));

  } catch (error) {

  }
}
const removeFromCart = (productId) => (dispatch, getState) => {
    dispatch({ type: CART_REMOVE_ITEM, payload: productId });
    const { cart: { cartItems } } = getState();
    Cookie.set("cartItems", JSON.stringify(cartItems));
}

const saveShippingAddress = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_SHIPPING, payload: data });
}

const savePayment = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_PAYMENT, payload: data });
}

const saveDeliveryMethod = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_DELIVERY, payload: data });
}


const saveDiscountCode = (data) => (dispatch) => {
  dispatch({ type: DISCOUNT_CODE, payload: data });
}

// const saveMSG = (data) => (dispatch) => {
//   dispatch({ type: CART_SAVE_MESSAGE, payload: data });
  
//   Cookie.set("Message", JSON.stringify(data));
// }
export { addToCart, removeFromCart, saveShippingAddress, savePayment, saveDeliveryMethod, saveDiscountCode }
