import {
    EVENT_LIST_REQUEST,
    EVENT_LIST_SUCCESS,
    EVENT_LIST_FAIL,
    EVENT_DETAILS_REQUEST,
    EVENT_DETAILS_SUCCESS,
    EVENT_DETAILS_FAIL,
    EVENT_SAVE_REQUEST,
    EVENT_SAVE_SUCCESS,
    EVENT_SAVE_FAIL,
    EVENT_DELETE_REQUEST,
    EVENT_DELETE_SUCCESS,
    EVENT_DELETE_FAIL, 
  } from '../constants/eventConstants';

  import axios from 'axios';
  import Axios from 'axios';

  
const listEvents = () => async (dispatch) => {
    try{
      dispatch({ type: EVENT_LIST_REQUEST });
      const { data } = await axios.get('/api/honeyevents' );
      dispatch({ type: EVENT_LIST_SUCCESS, payload: data });
    }
    catch(error){
      dispatch({ type: EVENT_LIST_FAIL, payload: error.message });

    }
  

}

const detailsEvent = (eventId) => async (dispatch) => {
  try {
    dispatch({ type: EVENT_DETAILS_REQUEST, payload: eventId });
    const { data } = await axios.get('/api/honeyevents/' + eventId);
    dispatch({ type: EVENT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: EVENT_DETAILS_FAIL, payload: error.message });
  }
};

const saveEvent = (event) => async (dispatch, getState) => {
  try {
    dispatch({ type: EVENT_SAVE_REQUEST, payload: event });
    const { userSignin: { userInfo } } = getState();
    if (!event._id) {
      const { data } = await Axios.post('/api/honeyevents', event, {
        headers: {
          'Authorization': 'Bearer ' + userInfo.token
        }
      });
      dispatch({ type: EVENT_SAVE_SUCCESS, payload: data });
    } else {
      const { data } = await Axios.put('/api/honeyevents/' + event._id, event, {
        headers: {
          'Authorization': 'Bearer ' + userInfo.token
        }
      });
      dispatch({ type: EVENT_SAVE_SUCCESS, payload: data });
    }

  } catch (error) {

    dispatch({ type: EVENT_SAVE_FAIL, payload: error.message });
  }
}

const deleteEvent = (eventId) => async (dispatch, getState) => {
  try {
    const { userSignin: { userInfo } } = getState();
    dispatch({ type: EVENT_DELETE_REQUEST, payload: eventId });
    const { data } = await axios.delete("/api/honeyevents/" + eventId, {
      headers: {
        Authorization: 'Bearer ' + userInfo.token
      }
    });
    dispatch({ type: EVENT_DELETE_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: EVENT_DELETE_FAIL, payload: error.message });

  }
}


export {listEvents, detailsEvent, saveEvent, deleteEvent};