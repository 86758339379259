import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  saveEvent, deleteEvent, listEvents
} from '../actions/eventActions';


function HoneyEventScreen(props) {
    const [modalVisible, setModalVisible] = useState(false);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [eventdate, setEventdate] = useState('');
    const [location, setLocation] = useState('');
    const [image, setImage] = useState('');
    const [webaddress, setWebaddress] = useState('');
    const [description, setDescription] = useState('');

    const eventList = useSelector(state => state.eventList);
    const { loading, events, error } = eventList;

    const eventSave = useSelector((state) => state.eventSave);
    const {
        loading: loadingSave,
        success: successSave,
        error: errorSave,
      } = eventSave;
    
    const eventDelete = useSelector(state => state.eventDelete);
    const { loading: loadingDelete, success: successDelete, error: errorDelete } = eventDelete;

    const dispatch = useDispatch();

    useEffect(() => {
        if (successSave) {
          setModalVisible(false);
        }
        dispatch(listEvents());
        return () => {
          //
        };
      }, [successSave, successDelete]);
    
    const openModal = (event) => {
        setModalVisible(true);
        setId(event._id);
        setName(event.name);
        setEventdate(event.eventdate);
        setLocation(event.location);
        setImage(event.image);
        setWebaddress(event.webaddress);
        setDescription(event.description);
        
        
      }

      const submitHandler = (e) => {
        e.preventDefault();
        dispatch(saveEvent({_id: id,name, eventdate,
            location,
            image,
            webaddress,
            description}));
    
      }

      const deleteHandler = (event) => {
        dispatch(deleteEvent(event._id));
      }

      return <div className="content content-margined">
          <div className="product-header">
            <h3>Events</h3>
            <button className="button" onClick={() => openModal({})}>Create Event</button>
        </div>
        {modalVisible &&
          <div className="form">
            <form onSubmit={submitHandler} >
                <ul className="form-container">
                    <li>
                        <h2> Create Event </h2>
                    </li>
                    <li>
                    {loadingSave && <div>Loading...</div>}
                    {errorSave && <div>{errorSave}</div>}
                    </li>
                    <li>
                    <label htmlFor="name">
                        Name
                    </label>
                    <input type="text" name="name" value={name} id="name" onChange={(e) => setName(e.target.value)}>
                    </input>
                    </li>
                    <li>
                    <label htmlFor="eventdate">
                        eventdate
                    </label>
                    <input type="date" name="eventdate" value={eventdate} id="eventdate" onChange={(e) => setEventdate(e.target.value)}>
                    </input>
                    </li>
                    <li>
                    <label htmlFor="location">
                        Location
                    </label>
                    <input type="text" name="location" value={location} id="location" onChange={(e) => setLocation(e.target.value)}>
                    </input>
                    </li>
                    <li>
                    <label htmlFor="image">
                        Image
                    </label>
                    <input type="text" name="image" value={image} id="image" onChange={(e) => setImage(e.target.value)}>
                    </input>
                    </li>
                    <li>
                    <label htmlFor="webaddress">
                        Website Address
                    </label>
                    <input type="text" name="webaddress" value={webaddress} id="webaddress" onChange={(e) => setWebaddress(e.target.value)}>
                    </input>
                    </li>
                    <li>
                    <label htmlFor="description">
                        Description
                    </label>
                    <textarea  name="description" value={description} id="description" onChange={(e) => setDescription(e.target.value)}>
                    </textarea>
                    </li>
                    <li>
                    <button type="submit" className="button primary">{id ? "Update" : "Create"}</button>
                    </li>
                    <li>
                    <button type="button" onClick={() => setModalVisible(false)} className="button secondary">Back</button>
                    </li>
                </ul>
            </form>
        </div>}

        <div className="product-list">

            <table className="table">
            <thead>
                <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Event Date</th>
                <th>Location</th>
                <th>Website Address</th>
                </tr>
            </thead>
            <tbody>
                {events.map(event => (<tr key={event._id}>
                <td>{event._id}</td>
                <td>{event.name}</td>
                <td>{event.eventdate}</td>
                <td>{event.location}</td>
                <td>{event.webaddress}</td>
                <td>
                <button className="button" onClick={() => openModal(event)} >Edit</button>
                {' '}
                <button className="button" onClick={() => deleteHandler(event)} >Delete</button>
                </td>
                </tr>))}
            </tbody>
            </table>

        </div>
          
        </div>;
          };
        
export default HoneyEventScreen;
