import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PayPalButton } from 'react-paypal-button-v2';
import React, { useEffect, useState } from 'react';
import { detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Axios from 'axios';
import { ORDER_PAY_RESET } from '../constants/orderConstants';


export default function OrderScreen(props) {
  const orderId = props.match.params.id;
  const [sdkReady, setSdkReady] = useState(false);
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  
  
  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;

  const dispatch = useDispatch();
  useEffect(() => {
    const addPayPalScript = async () => {
      const { data } = await Axios.get('/api/config/paypal');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.paypal.com/sdk/js?client-id=AUB7PEJYbodH8w9J851WIpXhhy8lPdOszRiVbGitspl-KFwOCdXFwasFwNpjBHMITuODFWyXlujX2Mg0&currency=GBP`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };
    if (!order || successPay || (order && order._id !== orderId)) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch(detailsOrder(orderId));
    } else {
      if (!order.isPaid) {
        if (!window.paypal) {
          addPayPalScript();
        } else {
          setSdkReady(true);
        }
      }
    }
  }, [dispatch, order, orderId, sdkReady]);
  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(order, paymentResult));
  }; 
  
  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (

    <div>
      <div className="order-heading">
            <h2>Order {order._id}</h2>
            
        </div>
      <div className='orderpage'>
        
        <div className="placeorder">
        
        <ul className="cart-list-container">
          <li>
            <div className="placeorder-info">
              <div className="order-info-box">
                <ul>
                  <h3>Order Summary</h3>
                    <strong>Email:</strong>{' '} {order.email}<br /><br />
                    <strong>Address: </strong> 
                    {order.name},{' '}
                  {order.shippingAddress.address},{' '}
                  {order.shippingAddress.city},{' '}
                  {order.shippingAddress.postalCode},{' '}
                  {order.shippingAddress.country}<br /><br />
                  <strong>Payment Status:</strong> Paid at {order.paidAt}<br /><br />
                  <strong>Items:</strong>
                    {'    '}£{(order.itemsPrice*1).toFixed(2)}<br /><br />
                  <strong>Shipping</strong>{'    '}£{(order.shippingPrice*1).toFixed(2)}<br /><br />
                  <strong>Order Total</strong>{'    '}£{(order.totalPrice*1).toFixed(2)}<br /><br />  
                </ul>
              </div>
          
              
              


            </div>
          </li>

          <li>
            <div className="cart-list-container">
              <h2>Order Items</h2>
              <ul>
                {order.orderItems.map((item) => (
                  <li key={item.product}>
                    <div className="row">
                      <div>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="small"
                        ></img>
                      </div>
                      <div >
                        <Link to={`/product/${item.product}`}>
                          {item.name}
                        </Link>
                      </div>

                      <div>
                        {item.qty} x £{item.price} = £{item.qty * item.price}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        </ul>
        

      </div>
    </div>
    </div>
    
  );
}