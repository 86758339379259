import React, { useEffect, useState} from'react'
import { Link } from 'react-router-dom';
import { useSelector , useDispatch} from 'react-redux';
import { signin } from '../actions/userActions';


function SigninScreen(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const userSignin = useSelector(state => state.userSignin);
    const { loading, userInfo, error } = userSignin;
    const dispatch = useDispatch();
    const redirect = props.location.search ? props.location.search.split("=")[1] : '/';

    useEffect(() => {
        if (userInfo) {
          props.history.push(redirect);
        }
        return () => {
          //
        };
      }, [userInfo]);

      const submitHandler = (e) => {
        e.preventDefault();
        dispatch(signin(email, password));
    
      }

      return <div>
          <div className="back-to-result">
            <Link to="/">Back to Shop</Link>
          </div>
          <div className="form">
            <form onSubmit={submitHandler} >
                <ul className="form-container">
                    <li>
                        <h2 className="sign-in-title"> Sign In </h2>
                    </li>
                    <li>
                    {loading && <div>Loading...</div>}
                    {error && <div>{error}</div>}
                    </li>
                    <li>
                    <label className='form-subtext-sec' htmlFor="email">
                        Email
                    </label>
                    <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)}>
                    </input>
                    </li>
                    <li>
                    <label className='form-subtext-sec' htmlFor="password">Password</label>
                    <input type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)}>
                    </input>
                    </li>
                    <li>
                    <button type="submit" className="button primary">Submit</button>
                    </li>
                    
                    <li className="form-subtext">
                    New to Nectar of the Dodd's?
                    </li>
                    <li>
                    <Link to="/register" className="button text-center"> Create your own Nectar of the Dodd's account</Link>
                    </li>
                </ul>
            </form>
        </div>
          
        </div>;
          };
        
export default SigninScreen;
