import { useDispatch, useSelector } from 'react-redux';
import { PayPalButton } from 'react-paypal-button-v2';
import React, { useEffect, useState } from 'react';
import { detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Axios from 'axios';
import { ORDER_PAY_RESET } from '../constants/orderConstants';
import nodemailer  from 'nodemailer';



export default function PaymentScreen(props) {

  const transporter = nodemailer.createTransport({
    service: 'gmail',
    auth: {
      user: 'NectaroftheDodds@gmail.com',
      pass: 'J1N3Tb22s'
    }
  });
  const mailOptions = {
    from: 'NectaroftheDodds@gmail.com',
    to: 'NectaroftheDodds@gmail.com',
    subject: 'Test',
    text: 'Ellie is the best!'
  };
  

  const orderId = props.match.params.id;
  const [sdkReady, setSdkReady] = useState(false);
  const orderDetails = useSelector((state) => state.orderDetails);
  const {order, loading, error } = orderDetails;
  
 
  
  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;

  const dispatch = useDispatch();
  useEffect(() => {
    const addPayPalScript = async () => {
      const { data } = await Axios.get('/api/config/paypal');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.paypal.com/sdk/js?client-id=AdcUPAmOiEoRlaoQofX6Ir4yQBb4BTM8PCCg-TW2yjv_23_qeCziDtoSbDqpn9PyPaH6IpNBcHR0O__N&currency=GBP`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };
    if (!order || successPay || (order && order._id !== orderId)) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch(detailsOrder(orderId));
      transporter.sendMail(mailOptions, function(error, info){
        if (error) {
          console.log(error);
        } else {
          console.log('Email sent: ' + info.response);
        }
      });

    } else {
      if (!order.isPaid) {
        if (!window.paypal) {
          addPayPalScript();
        } else {
          setSdkReady(true);
        }
      }
    }
  }, [dispatch, order, orderId, sdkReady]);
  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(order, paymentResult));
    props.history.push(`/order/${order._id}`);
  }; 
  
  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div className='paymentpage'>

        <div className="placepayment">
        
        
        <div >
        <div className="payment-action">
          <ul>
            <li>
              <h2>Payment Summary</h2>
            </li>
            <li>
              <div className="row">
                <div>Items: </div>
                 <div >£{(order.itemsPrice*1).toFixed(2)}</div> 
              </div>
            </li>
            <li>
              <div className="row">
                <div>Shipping</div>
                 <div>£{(order.shippingPrice*1).toFixed(2)}</div> 
              </div>
            </li>
            <li>
              <div className="row">
                <div>Discount</div>
                 <div>£{((order.totalPrice*1).toFixed(2)-(order.itemsPrice*1).toFixed(2)-(order.shippingPrice*1).toFixed(2)).toFixed(2)}</div> 
              </div>
            </li>

            <li>
              <div className="row">
                <div>
                  <strong> Payment Total</strong>
                </div>
                <div >
                  <strong>£{(order.totalPrice*1).toFixed(2)}</strong>
                </div>
              </div>
            </li>
  
              <div className="paypal">
                <li>
                  {!sdkReady ? (
                    <LoadingBox></LoadingBox>
                  ) : (
                    <>
                      {errorPay && (
                        <MessageBox variant="danger">{errorPay}</MessageBox>
                      )}
                      {loadingPay && <LoadingBox></LoadingBox>}
  
                      <PayPalButton 
                        amount={(order.totalPrice*1).toFixed(2)}
                        currency="GBP"
                        onSuccess={successPaymentHandler}
                      ></PayPalButton> 
                    </>
                  )}
                </li>
              </div>
              <div >
              
              
              {order.isPaid ? (
                <MessageBox variant="success">
                  <strong>Payment Status:</strong> Paid
                  
                </MessageBox>
                
              ) : (
                <MessageBox variant="danger"> <strong>Payment Status:</strong> Not Paid</MessageBox>
              )}
            </div>
          </ul>

        </div>
      </div>
      </div>
    </div>
  );
}

