import {
    EVENT_LIST_REQUEST,
    EVENT_LIST_SUCCESS,
    EVENT_LIST_FAIL,
    EVENT_DETAILS_REQUEST,
    EVENT_DETAILS_SUCCESS,
    EVENT_DETAILS_FAIL,
    EVENT_SAVE_REQUEST,
    EVENT_SAVE_SUCCESS,
    EVENT_SAVE_FAIL,
    EVENT_DELETE_REQUEST,
    EVENT_DELETE_SUCCESS,
    EVENT_DELETE_FAIL, 
  } from '../constants/eventConstants';

function eventListReducer(state = { events: [] }, action) {
    switch (action.type) {
      case EVENT_LIST_REQUEST:
        return { loading: true, events: [] };
      case EVENT_LIST_SUCCESS:
        return { loading: false, events: action.payload };
      case EVENT_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
}

function eventDetailsReducer(state = { event: { reviews: [] } }, action) {
  switch (action.type) {
    case EVENT_DETAILS_REQUEST:
      return { loading: true };
    case EVENT_DETAILS_SUCCESS:
      return { loading: false, event: action.payload };
    case EVENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function eventSaveReducer(state = { event: {} }, action) {
  switch (action.type) {
    case EVENT_SAVE_REQUEST:
      return { loading: true };
    case EVENT_SAVE_SUCCESS:
      return { loading: false, success: true, event: action.payload };
    case EVENT_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function eventDeleteReducer(state = { event: {} }, action) {

  switch (action.type) {
    case EVENT_DELETE_REQUEST:
      return { loading: true };
    case EVENT_DELETE_SUCCESS:
      return { loading: false, event: action.payload, success: true };
    case EVENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state;
  }
}

export { eventListReducer ,  eventDetailsReducer, eventSaveReducer, eventDeleteReducer}