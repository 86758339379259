import React, { useEffect,useState } from 'react';

import { addToCart , removeFromCart} from '../actions/cartActions';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { create, all, string} from 'mathjs'

function CartScreen(props) {
    
    const config = { }
    const cart = useSelector(state => state.cart);
    const math = create(all, config)
    const { cartItems } = cart;
    const qty = props.location.search ? Number(string(props.location.search.split("qty=")[1]).split("?")[0]) : "not found";
    const msg = props.location.search ? string(props.location.search.split("=")[2]) : "";
    const productId = props.match.params.id;
    const dispatch = useDispatch();
    const removeFromCartHandler = (productId) => {
        dispatch(removeFromCart(productId));
    }
    const toPrice = (num) => Number(num).toFixed(2); 
    const item_delivery_factor = cartItems.reduce((a, c) => a + c.delivery_max * c.qty, 0);
    const shippingPrice = toPrice(3.5 * math.ceil(item_delivery_factor));
    useEffect(() => {
        if (productId) {
          dispatch(addToCart(productId, qty, msg));
        }
      }, []);
    

  
    
    const checkoutHandler = () => {
    props.history.push("/shipping");
    }
   
    return <div>
        <div className="back-to-result">
            <Link to="/">Back to Shop</Link>
        </div>
        <div className="cart">
            <div className="cart-list">
                <ul className="cart-list-container">
                    <li>
                        <h3 className="details">
                            Basket
                        </h3>
                        <div className="details">
                            Price
                        </div>
                    </li>
                    {cartItems.length === 0 ?
                    <div>
                        Basket is empty :(
                    </div>
                    :
                    cartItems.map(item =>
                    <li>
                        <div className="cart-image">
                            <img src={item.image} alt="product" />
                        </div>
                        <div className="cart-name">
                            <div>
                                <Link to={"/product/" + item.product}>
                                {item.name}
                                </Link>
                            </div>
                        <div>
                            Qty:
                            <select value={item.qty} onChange={(e) => dispatch(addToCart(item.product, e.target.value))}>
                                {[...Array(item.countInStock).keys()].map(x =>
                                    <option key={x + 1} value={x + 1}>{x + 1}</option>
                                )}
                            </select>
                            <button type="button" className="button" onClick={() => removeFromCartHandler(item.product)} >
                            Remove
                            </button>
                            {item.msg === "" ? 
                            <div></div>:
                            <div>
                            Gift Message : {item.msg}
                           </div>
                            }
                            

                        </div>
                    </div>
                        <div className="cart-price">
                        £{ toPrice(item.price)}
                        </div>
            
                        
                    </li>
                    )
                    }

                </ul>
            </div>
            <div className="cart-action">
            <h3>
                Subtotal ( {(cartItems.reduce((a, c) => a + 1 * c.qty, 0)*1)} items)
                :
                £ {toPrice(cartItems.reduce((a, c) => a + c.price * c.qty, 0))}
            </h3>
        

            
        
            <h3>
                Shipping
                :
                £{toPrice(shippingPrice)}
            </h3>
 
        <button onClick={checkoutHandler} className="button primary full-width" disabled={cartItems.length === 0}>
            Proceed to Checkout
        </button>
        <div>
            You can choose to collect and add discount codes on the order page.  
        </div>
            
            </div>
        </div>

    </div>
}

export default CartScreen;
