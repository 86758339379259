import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CheckoutSteps from '../components/CheckoutSteps';
import { createOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { ORDER_CREATE_RESET } from '../constants/orderConstants';
import { create, all } from 'mathjs'
import { saveDiscountCode } from '../actions/cartActions';

function PlaceCollectionScreen(props) {
  const config = { }
  const math = create(all, config)
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const cart = useSelector(state => state.cart);
  const [discountcode, setDiscount] = useState('');
  const { cartItems, shipping, payment } = cart;
  if (!shipping.name) {
    props.history.push("/shipping");
  } 
 
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveDiscountCode({discountcode}));
    if(discountcode=='NOTDSPR10'){props.history.push('discounted');}
    
  }
 


  const orderCreate = useSelector((state) => state.orderCreate);
  const { loading, success, error, order } = orderCreate;
  
  const toPrice = (num) => Number(num.toFixed(2)); // 5.123 => "5.12" => 5.12

  const itemsPrice = cartItems.reduce((a, c) => a + c.price * c.qty, 0);

  const item_delivery_factor = cartItems.reduce((a, c) => a + c.delivery_max * c.qty, 0);

  // cart.item_delivery_factor = toPrice(
  //   cart.cartItems.reduce((a, c) => a + c.delivery_max * c.qty, 1)
  // );
  cart.itemsPrice = toPrice(
    cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0)
  );

  //cart.itemsthresh = cart.cartItems.reduce((a, c) => a + c.qty * c.delivery_max, 2)

 
  cart.discount = 0
  cart.totalPrice = cart.itemsPrice + cart.discount;

  cart.shippingAddress= cart.shipping

  cart.shippingPrice = 0
  
  
  {
    userInfo ?               
    cart.name=userInfo.name:
    
    cart.name=cart.shipping.name
    }
  {
    userInfo ?   
    cart.email=userInfo.email: cart.email=cart.shipping.email}
  


  const totalPrice = itemsPrice ;
  

  const dispatch = useDispatch();

  const placeOrderHandler = () => {
    dispatch(createOrder({ ...cart, orderItems: cart.cartItems }));
  }
  useEffect(() => {
    if (success) {
      props.history.push(`/payment/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [dispatch, order, props.history, success]);

  return <div>
  <div className="back-to-result">
    <CheckoutSteps step1 step2 step3 ></CheckoutSteps>
    </div>
      <div className="order">
        <div className="order-list"> 

        <div className='orderpage'>
         
         <div className="placeorder">
         
         <ul className="order-container">
           <li>
              <div className="placeorder-info">
                  <div className="order-info-box">
                    <ul>
                    
                        <h3>Order Summary</h3>
                      <strong>Name:</strong>{'    '} {cart.shipping.name}<br /><br />
                      <strong>Email:</strong>{'    '} {cart.shipping.email}<br /><br />
                      <strong>Items:</strong>
                      {'    '}£{(itemsPrice*1).toFixed(2)}<br /><br />
 
                      
                      <strong>Discount</strong>{'    '}£{((-1*cart.discount)*itemsPrice).toFixed(2)}<br /><br />
            
                      <strong>Order Total</strong>{'    '}£{(totalPrice).toFixed(2)}<br /><br />

                      <form onSubmit={submitHandler} >
                      
                      <label htmlFor="discountcode">
                        Discount Code:
                      </label>
                      <input type="text" name="discountcode" id="discountcode" placeholder="Enter discount code" onChange={(e) => setDiscount(e.target.value)}>
                       
                      </input>
                      
                      <button type="submit" className="button primary">Apply</button>

                      </form>
                      <button className="button primary full-width" onClick={placeOrderHandler} >Continue to Payment</button>
                
                      {loading && <LoadingBox></LoadingBox>}
                      {error && <MessageBox variant="danger">{error}</MessageBox>}
                    </ul>
                  </div>
                
              </div>
            </li>
            
            </ul>
            </div>
            </div>

          <div>
            <ul className="cart-list-container">
              <li>
                <h3>Order</h3>
                <div>Price</div>
              </li>
                {
                  cartItems.length === 0 ?
                    <div>Cart is empty</div>:
                      cartItems.map(item =>
                        <li>
                          <div className="cart-image">
                            <img src={item.image} alt="product" />
                          </div>
                          <div className="cart-name">
                            <div>
                              <Link to={"/product/" + item.product}>
                                {item.name}
                              </Link>
                            </div>
                            <div>
                              Qty: {item.qty}
                            </div>
                            {item.msg === "" ? 
                            <div></div>:
                            <div>
                            Gift Message : {item.msg}
                           </div>
                            }
                      
                          </div>
                          <div className="cart-price">
                            £{(item.price*1).toFixed(2)}
                          </div>
                        </li>)}
              </ul>
           </div>
            
          </div>
       </div>
    </div>
  

}

export default PlaceCollectionScreen;