import React, { useEffect } from'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { listEvents } from '../actions/eventActions';


function EventScreen(props) {

    const eventList = useSelector((state) => state.eventList);
    const { events, loading, error } = eventList;
    const dispatch = useDispatch();
    useEffect(() =>{
        dispatch(listEvents());
        return () => {
        //
        };
    }, []
    )
     
    return loading ? <div>Loading...</div>:
        error ? <div>{error}</div>:
            <ul className="products">{
                events.map(HoneyEvent =>
                    <li key={HoneyEvent._id}>
                        <div className="product">
                            
                            <div className="product-brand">{HoneyEvent.name}</div>
                            <div className="product-brand">{HoneyEvent.location}</div>
                            <div className="product-brand">{HoneyEvent.eventdate.split('T')[0]}</div>
                            <div>
                            <img src={HoneyEvent.image} className="medium"></img>
                            </div>
                            <div className="product-brand">{HoneyEvent.description}</div>
                            <div> </div>
                            
                        </div>
                    </li>)
            }
            </ul>
}
export default EventScreen;