import React from'react'
import { Link } from 'react-router-dom';



function AboutScreen(props) {     

      return<div>
      <div className="back-to-result">
        <Link to="/">Back to Shop</Link>
      </div>
      <div className="about">
        <div className="about-info">
        <h1>A bit about me!</h1>
          <p>Like a lot of people, I love animals. All animals! <br></br>In my home, we’ve had anything from dogs to cats, mice to tortoises, fish to birds. <br></br>Not spiders though. <br></br>Never spiders.<br></br>
          Now that I am retired, I have been blessed with the time to dive into the good life. An obvious pursuit was beekeeping. I find it all fascinating and conversations with me have become quite a burden to my family as it is all I talk about.<br></br>
          What started as a hobby has become a much larger endeavour than I ever expected. A modest supply of honey is now a significant store and so, I have decided to expand my hobby to an online business. With the help of my more tech savvy children of course.</p>
          <br></br><br></br><br></br>
        </div>
        <div className="about-image">
            <img src="images/about_width.png" alt="about"></img>
        </div>
                  
                 
        </div>
      </div>;
      }; 


export default AboutScreen;
 