import React, { useEffect} from'react'
import { Link } from 'react-router-dom';
import { useDispatch} from 'react-redux';


function KeywordScreen(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        
        return () => {
          //
        };
      }, []);

      const submitHandler = (e) => {
        e.preventDefault();
    
      }

       

      return<div>
      <div className="back-to-result">
        <Link to="/">Back to Shop</Link>
      </div>
      <div className="about">
        <div className="about-info">
        <h1>A bit about me!</h1>
          <p>Like a lot of people, I love animals. All animals! <br></br>In my home, we’ve had anything from dogs to cats, mice to tortoises, fish to birds. <br></br>Not spiders though. <br></br>Never spiders.<br></br>
          Now that I am retired, I have been blessed with the time to dive into the good life. An obvious pursuit was beekeeping. I find it all fascinating and conversations with me have become quite a burden to my family as it is all I talk about.<br></br>
          What started as a hobby has become a much larger endeavour than I ever expected. A modest supply of honey is now a significant store and so, I have decided to expand my hobby to an online business. With the help of my more tech savvy children of course.</p>
          Pictures
Honeycomb
Mothers day gift box
Valentines day gift box


Product Ideas

Seed balls
Lavender bags
Sprinkle lavender in box
Honey Drippers
honeycomb

Gift Boxes

Mother’s day – priority March, honey, dripper, seed bomb, inner decoration

Valentine’s day – Dripper and honey and candle, red ribbon, heart gift tag ,have hearts




Keywords:

wildflower seeds
Beekeeper
Honey Bulk
Dadant hive – talk about type of bee keeping equipment used, how to start up
How to install a nuc



stuff on save the bees



Visit us at https://www.nectarofthedodds.com and make sure to follow us for all things bees and honey!
#nectarofthedodds #honey#beeswax #candles #honeybees#naturalhoney #rawhoney #nature#natural #savethebees #wiltshire#pewsey #marlborough #swindon#localhoney #localbusiness #family















Here are a few links to useful beekeeping websites:

Salisbury Beekeeping Association 
www.salisburybka.co.uk/

Wiltshire Beekeepers 
www.wiltshirebeekeepers.org.uk

Beebase
www.nationalbeeunit.com

Beekeeping forum
www.beekeepingforum.co.uk

Buckfast Beekeepers Group
www.buckfastbeekeepersgroup.co.uk

Honeybee Health
www.advancescience.com

Alan Wadsworth
www.alanwadsworth.co.uk

Learn Beekeeping
www.learnbeekeeping.org.uk

Bees Over Britain
www.bees-over-britain.co.uk



Other useful websites

Ringstones Media for website design and other digital services www.ringstonesmedia.co.uk/

Recipes with honey

Story of akin using mums honey for his cider




According to its color the honeys can be classified in white, amber and dark honeys. Amber honeys are in the middle. They are a mix of all the honey properties. If you want to take advantage of the restorative, energetic value and the nutritional properties of white honey while taking advange of the phenomenal properties of dark honeys, your choice should be organic amber honey. Amber honey is always of multifloral origin. When it is considered monofloral the amount of the dominant flower is about 15% of the pollen.  Organic amber honey is a very beneficial complement to our health. Among the amber honeys that you can buy at orghoney.com there are wildflower honey, lavender honey and thyme honey,in both versions organic honey and artisan honey.


1lb of  pound of our different flowers appear at different times of the year and so the nectar used varies which makes the flavour of each of our jars slightly different. Our honey is completely natural, raw, untouched! 
Heating honey, like most commercial honey has been, removes so much of the natural goodness of honey, like killing enzymes and nutrients. 
So not only is our honey extremely delicious and unique it is also a superfood.

Or raw honey is as it exists in the beehive, it contains a wide variety of nutrients, 22 amino acids, 31 different minerals and a wide range of vitamins and enzymes. Commercial honey goes through a pasteurization and filtration process and loses most of the nutrients and enzymes. Our bees also visit a wide range of flowers which appear at different times of the year and so the nectar used varies which makes the flavour of each of our jars slightly different.
We could go on all day about the benefits of raw, all natural honey so follow us on social media or read some of our blog posts!

Our All-in giftbox is exactly that, we put it all in! We have our all natural NOTD honey with honey drizzler, dried lavender drawer bag, wildflower seed balls and beehive skep candle. 
The perfect giftbox for Mother’s Day or for a Bee/Honey enthusiast.



What’s most impressive about raw honey is that it contains nearly 30 types of bioactive plant compounds. These are called polyphenols, and they act as antioxidants.
Many studies have linked these antioxidants with impressive health benefits, including reduced inflammation and a lower risk of heart disease and certain cancers.

Commercial honey doesn’t contain any pollen, it is removed in the pasteurization and filtration process as is most of the nutrients and enzymes.
Bees travel from flower to flower collecting nectar and pollen.
The nectar and pollen are taken back to the beehive, where they are packed into the honeycomb and eventually become a food source for the bees.
Bee pollen is surprisingly nutritious and contains over 250 substances, including vitamins, amino acids, essential fatty acids, micronutrients and antioxidants.

Bee pollen has been linked to many impressive health benefits. Studies have found that it may help fight inflammation and improve liver function. It also has properties that may help fight against heart disease and stroke.




10 for jar of honey and 5 seed balls

Jar honey dipper 5 seed balls candle lavender. 18

Jar honey dipper 10 seed balls. 18

Honey and dipper 8

Honey and candle 10

Honey and lavender 8 

20 seed balls for £15









1 pound of honey
a swarm of bees
acacia honey
acacia raw honey
adopt a bee
all natural honey
amber honey
apiaries
apiary
apiguard
apilife var
argan oil bulk
argan oil wholesale
artisan honey
aspen bark
aspen bark extract
australian honey
az hive
backyard beehive
base soap
bath bomb molds
bath butter
bear honey
beard balm recipe
bee
bee box
bee candles
bee club
bee farm
bee fondant
bee gift ideas
bee harvesting
bee hive
bee hive box
bee hive boxes
bee hive kits
bee hives
bee hives for sale
bee honey
bee house
bee keeper
bee keeping
bee nucs for sale
bee online
bee pollen
bee products
bee shop
bee skep
bee smoker
bee space
bee store
bee suit
bee suits
bee supplies
bee waggle dance
bee wax
bee wrap
bee yard
bee's wax
beecause
beecraft
beehive
beehive box
beehive honey
beehives
beehives for sale
beeinventive
beekeeper
beekeepers
beekeeping course
beekeeping equipment
beekeeping gloves
beekeeping store
beekeeping suit
beekeeping supplies
bees bearding
bees house
bees in summer
bees wax
bees wax candles
beeswax
beeswax benefits
beeswax block
beeswax bulk
beeswax candle
beeswax candle making
beeswax candle recipe
beeswax candles
beeswax food wrap
beeswax food wraps
beeswax for candles
beeswax for hair
beeswax for sale
beeswax for skin
beeswax in bulk
beeswax in soap
beeswax lip balm
beeswax moisturizer
beeswax pastilles
beeswax pellets
beeswax pillar candle
beeswax taper candles
beeswax tealight candles
beeswax uses
beeswax wrap
beeswax wraps
beewax
behive
benefits of beeswax
benzoin essential oil
bergamot oil benefits
bergamot soap
bergaptene
beshoop
best beeswax
best honey
best honey brand
best honey to buy
best natural honey
best organic honey
best raw honey
biggest honey brands
black cumin seed oil benefits
black seed honey
blood orange essential oil
blossom honey
bluecorn beeswax
british honey
broccoli seed oil
brood box
bucket of honey
buckwheat honey
bulk beeswax
bulk essential oil
bulk essential oils
bulk honey
bulk manuka honey
bulk oils
bulk peppermint oil
bulk raw honey for sale
bulk shea butter
bulk sodium bicarbonate
buriti oil
butterfly pads
buy bee
buy beeswax
buy essential oils
buy essential oils in bulk
buy honey
buy honey online
buy honeycomb
buy honeycomb near me
buy local honey
buy organic honey
buy pure honey
buy raw honey
buy raw honeycomb
california honey
candle and soap making supplies
candle beeswax
candle fragrance oils
candle mold
candle supplies
candle wax
capilano honey
carob honey
carrot seed oil
carrot seed oil benefits
castor oil bulk
castor oil wholesale
certan
cheap essential oils
cheap honey
cheapest honey
chestnut honey
chicken products
clear honey
coffee essential oil
color of honey
colorado honey
comb honey
company near me
coriander honey
cox honey
creamed honey
creamy honey
crystal opc
dabur honey
decrystallize honey
difference between raw honey
different types of bees
diy beeswax candles
eat honeycomb
eating honeycomb
eating raw honeycomb
emulsifying wax
essential oil bulk
essential oil suppliers
essential oil wholesale
essential oils in bulk
essential oils suppliers
essential oils wholesale
eucalyptus honey
extracting honey
fake honey
fake honey brands
farm honey
farm tour
farm vegetables
farmers market near me
farmers' market
feeders
fermented honey
filtered honey
fir honey
flavored honey
flow
flow bee
flow beehive
flow frame
flow frames
flow hive
flow hive 2
flow hive coupon
flow hives
flow honey
flow honey hive
flowhive
flowing honey
foaming bath butter
foaming bath whip
food grade beeswax
forest honey
fractionated coconut oil
fragrance for soap
fragrance oil
fragrance oil for soap
fragrance oils
fragrance oils for candles
fragrance oils for soap
frankincense smell
french clay
french green clay
fresh honey
fresh honeycomb
gallon honey
gallon of honey
garlic essential oil
georgia honey
germaben ii
germall plus
glass cleaner price
glycol stearate
goat milk soap base
goats milk soap base
golden honey
golden wax
greek honey
green clay
harvest honey
harvesting honey
healthiest honey
heather honey
herbal oil
hilltop honey
hive
hive bee
hive honey
hive music
hive store
hive tools
hive work
hive.
hive.com
hoeny
homemade soap
honecomb
honey
honey .com
honey association
honey australia
honey bee house
honey blog
honey box
honey brand
honey brands
honey brands in india
honey buy
honey buy online
honey canada
honey candle
honey candles
honey clothing
honey code
honey colorado
honey com
honey comb
honey companies
honey company
honey cost
honey discounts
honey energy
honey extraction
honey faq
honey farm
honey farms near me
honey fermentation
honey flow
honey flow hive
honey for sale
honey for sale near me
honey for weight loss
honey gift ideas
honey gift set
honey gifts
honey harvest
honey harvesting
honey hive
honey in india
honey in pakistan
honey in tea
honey india
honey ingredients
honey jars
honey label
honey label requirements
honey labeling
honey login
honey london
honey man
honey near me
honey nyc
honey on amazon
honey on line
honey on tap
honey online
honey online india
honey online shopping
honey packaging
honey per pound
honey press
honey price
honey price tracker
honey prices
honey prices 2018
honey products
honey pump
honey pure
honey sale
honey sales
honey shelf life
honey shop
honey shopify
honey shopping
honey sticks
honey stix
honey store
honey stores
honey subscription
honey tap
honey testing
honey uk
honey vs
honey vs manuka honey
honey vs raw honey
honey water
honey website
honey weight
honey with honeycomb
honey worth
honey.com
honeycomb
honeycomb for sale
honeycomb frame
honeycomb honey
honeycomb near me
honeyflow
honeys
honeystore
hoop pine
how to extract honey
how to make beeswax candles
how to make mango butter
how to make soap base
how to make whipped soap
how to sell soap
how to use beeswax
hungarian acacia honey for sale
is honey processed
jamun honey
jar of honey
jojoba oil bulk
jojoba oil wholesale
kaolin clay
kochstar
kokum butter
langnese honey
langstroth
langstroth hive
lavandin
lavender honey
light honey
lime honey
lip balm base
liquid castile soap
liquid germall plus
litsea cubeba
local farmers market
local honey
local honey farm
local honey for sale
local honey near me
local honey store
local organic honey
local raw honey
london bee
london bee company
london honey
lotion base
lye
lye for soap making
make beeswax candles
mango butter
mango butter soap
manukadoctor
meadowfoam oil
melt and pour
melt and pour base
melt and pour soap
melt and pour soap base
menthol crystals
mgo
mgo honey
mgo stands for
michigan honey
milkpak
monofloral
most expensive honey
mountain honey
multifloral honey
natural beeswax
natural honey
natural honey online
nesfruta
new directions aromatics
nhb
nicot queen rearing
oak & honey
oak honey
oil fragrance
optiphen plus
orange blossom honey
orange honey
order honey
organic beeswax
organic candles
organic honey
organic honey in india
organic honey near me
organic honey online
organic honey price
organic honey uk
organic soap base
original honey
patanjali honey
peppermint oil
pine honey
pollinator house
polysorbate 20
polystyrene hive
pomegranate oil
pomegranate seed oil
pound of honey
pour soap base
premium honey
price of honey
processed honey
pure beeswax
pure honey
pure honey online
pure raw honey
quality honey
raspberry honey
raw acacia honey
raw bee pollen
raw forest honey
raw honey
raw honey benefits
raw honey brands
raw honey comb
raw honey for sale
raw honey manuka
raw honey near me
raw honey online
raw honey online india
raw honey prices
raw honey vs regular
raw honeycomb
raw local honey
raw manuka honey
raw orange blossom honey
raw organic honey
raw propolis
raw shea butter
raw unpasteurized honey
real honey
really raw honey
rebatch soap
rhassoul clay
rooftop honey
rose clay
sage honey
sal butter
salisbury london
savannah bee company
scented beeswax candles
scented oils
sea clay
sell honey
set honey
sfic soap
shampoo base
shea butter
shea butter bulk
shea butter wholesale
shop honey
shop manuka honey
slovenian beehive
smiley honey
smoke bees
soap base
soap base recipe
soap base wholesale
soap bases
soap colors
soap fragrance
soap making
soap making supplies
soap mold
soap molds
soap packaging
soap supply
soapmaking
sodium hydroxide lye
soy wax
soy wax wholesale
spice honey
spun honey
stearic acid uses
stephenson soap
stephenson soap base
sugared honey
sunflower honey
sunflower oil for skin
sweet almond oil
swienty
texas honey
the bee store
the flow hive
the hive
the honey
the honey shop
the raw honey shop
thyme honey
tupelo honey
turkish honey
types of honey
unfiltered honey
unpasteurized honey
unrefined shea butter
vanilla oleoresin
vegetable glycerin
vetiver benefits
vetiver essential oil
wax foundation
what is beeswax
what is fractionated coconut oil
what is honey
what is thyme honey
where to buy beeswax
where to buy honey
where to buy honeycomb
where to buy local honey
where to buy raw honey
where to buy real honey near me
whipped honey
whipped soap
whipped soap base
whipped soap recipe
white beeswax
white honey
wholesale beeswax
wholesale essential oil
wholesale essential oils
wholesale honey
wholesale soap
why does honey crystallize
wild honey in mountain
wildflower honey
wyoming honey
yellow beeswax
your hive
ÿπÿ≥ŸÑ
ÿπÿ≥ŸÑ 24
          <br></br><br></br><br></br>
        </div>
        <div className="about-image">
            <img src="images/about_width.png" alt="about"></img>
        </div>
                  
                 
        </div>
      </div>;
      }; 


export default KeywordScreen;
 