import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { saveShippingAddress } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';


function CollectionScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const cart = useSelector((state) => state.cart);
  const { shipping} = cart;

 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const address = '77 Fifield';
  const city = 'Enford';
  const postalCode = 'SN9 6DQ';
  const country = 'UK';

  const dispatch = useDispatch();


  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ name, email, address, city, postalCode, country}));
    props.history.push('placecollectionorder');
  }
  

  return <div>
      <CheckoutSteps step1 step2 ></CheckoutSteps>
      <div className="back-to-result">
        <Link to="/">Back to Shop</Link>
      </div>


      <div id = "div1" className="form">
          
        <form onSubmit={submitHandler} >
          <ul className="form-container">
            <li>
              <h2>Collection Details</h2>
            </li>

            <li><label htmlFor="name">
                  Name
                </label>
                <input type="text" name="name" id="name" placeholder="Enter full name" onChange={(e) => setName(e.target.value)}>
                </input></li>
              
            
            
 
            <li>
              <label htmlFor="email">Email: </label>
              <input type="text" name="email" id="email" placeholder="Enter your email address" onChange={(e) => setEmail(e.target.value)}>
              </input>
            </li>
            
            
           
            
            
            <li>
              <button type="submit" className="button primary">Continue</button>
            </li>

            <div className='collectiontext'>
          Your order will be ready to collect from 24 hours after you place it, gives us a chance to get things together. <br></br>
      We will keep them by for 2 weeks, but just send us a message either on facebook, instagram or email if you won't get a chance to collect before then.<br></br>
      Just let us know your name and order number.
          </div>
          </ul>
          
        </form>
      </div>
      </div>
}
export default CollectionScreen;