export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST';
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS';
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL';

export const EVENT_DETAILS_REQUEST = 'EVENT_DETAILS_REQUEST';
export const EVENT_DETAILS_SUCCESS = 'EVENT_DETAILS_SUCCESS';
export const EVENT_DETAILS_FAIL = 'EVENT_DETAILS_FAIL';

export const EVENT_SAVE_REQUEST = 'EVENT_SAVE_REQUEST';
export const EVENT_SAVE_SUCCESS = 'EVENT_SAVE_SUCCESS';
export const EVENT_SAVE_FAIL = 'EVENT_SAVE_FAIL';

export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL';
