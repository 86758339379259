import React, { useEffect, useState} from'react'
import { Link } from 'react-router-dom';
import { useSelector , useDispatch} from 'react-redux';
import { detailsProduct } from '../actions/productActions';

function ProductScreen(props) {
    const [qty, setQty] = useState(1);
    const productDetails = useSelector((state) => state.productDetails);
    const { product, loading, error } = productDetails;
    const dispatch = useDispatch();

    const [personalMSG, setMsg] = useState('');

    useEffect(() => {
        dispatch(detailsProduct(props.match.params.id))
        return () => {
          //
        };
      }, []);

      const handleAddToCart = () => {
        props.history.push('/cart/' + props.match.params.id + '?qty=' + qty  + '?msg=' + personalMSG);
        
      };

      return <div>
          <div className="back-to-result">
            <Link to="/">Back to shop</Link>
          </div>
          {loading ? <div>Loading...</div> : 
          error ? <div>{error} </div>: 
          (
            <div className="details">
                  <div className="details-image">
                      <img src={product.image} alt="product"></img>
                  </div>
                  <div className="details-info">
                      <ul>
                          <li>
                              <h4>{product.name}</h4>
                          </li>
                          <li>
                              <b>Price: £{(product.price*1).toFixed(2)}</b>
                          </li>
                          <li>
                              Description: 
                              <br></br>
                              <br></br>
                              <div>
                                  {product.description}
                              </div>
                          </li>
                      </ul>
                  </div>
                  <div className="details-action">
                      <ul>
                          <li>
                              Price: £{(product.price*1).toFixed(2)}
                          </li>

                          <li>
                               {product.countInStock > 0? "" : "Sorry, we are out of stock our busy bees are resting!"}
                          </li>
                        
                            <li>
                                Qty:{' '}
                                <select
                                    value={qty}
                                        onChange={(e) => {
                                        setQty(e.target.value);}}>
                                            {[...Array(product.countInStock).keys()].map((x) => (
                                            <option key={x + 1} value={x + 1}>
                                                {x + 1}
                                            </option>
                                            ))}
                                </select>
                            </li>
                        {product.brand === "Giftbox" ? 
                            <li>
                                {/* <label htmlFor="personalMSG">
                                    Add a Message
                                </label> */}
                                <input className="msgbox" type="text" name="personalMSG" id="personalMSG" placeholder="Enter gift tag message or leave blank" onChange={(e) => setMsg(e.target.value)}></input>
                            </li>:
                        <div></div>
                        }


                          <li>
                              {product.countInStock > 0 && <button onClick={handleAddToCart} className='button'>
                                  Add to Basket
                              </button>
                              }
                            
                          </li>
                      </ul>
                  </div>
              </div>
          )
        }
        </div>;
          };
        
export default ProductScreen;
